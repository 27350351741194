import { http } from 'connex-cds';

export default {
  listEntities: () => http.get({ apiName: 'admin', path: `/entities?activeOnly=false` }),
  getEntity: ({ entityRef }) => http.get({ apiName: 'admin', path: `/entities/${entityRef}` }),
  createEntity: ({ entity }) => http.post({ apiName: 'admin', path: `/entities`, data: entity }),
  deleteEntity: ({ entityRef }) => http.delete({ apiName: 'admin', path: `/entities/${entityRef}` }),
  createEntityProfileConnection: ({ entityRef, profileRef }) =>
    http.post({ apiName: 'admin', path: `/entities/${entityRef}/connexions/profiles/${profileRef}` }),
  createEntityPartnerConnection: ({ entityRef, partnerRef }) =>
    http.post({ apiName: 'admin', path: `/entities/${entityRef}/connexions/partners/${partnerRef}` }),
  updateEntity: ({ entityRef, entity }) =>
    http.patch({ apiName: 'admin', path: `/entities/${entityRef}`, data: entity }),
  // deleteConnection: ({ connectionRef }) => http.delete({ apiName: 'admin', path: `/connexions/${connectionRef}` }),
  listConnections: ({ entityRef, type }) =>
    http.get({
      apiName: 'admin',
      path: `/entities/${entityRef}/connexions`,
      queryParams: type ? { type } : undefined,
    }),
};
