import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { usePostMessageContext } from '../post-message/PostMessage';
import { usePostMessageHandler } from './usePostMessageHandler';

export const useClearMenu = () => {
  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    sendMessage({ menu: null });
  }, [sendMessage]);
};

export const useInitializeMenu = menu => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { sendMessage } = usePostMessageContext();
  const { entityRef } = useParams();

  React.useEffect(() => {
    sendMessage({ locationChange: pathname });
  }, [pathname, sendMessage]);

  React.useEffect(() => {
    if (menu) {
      sendMessage({ menu });
    }
  }, [menu, sendMessage]);

  const handleMessageEvent = React.useCallback(
    event => {
      const message = event?.data;
      if (message?.menuItemClicked) {
        const destination = message.menuItemClicked.path;
        // const destination = `${entityRef ? `/${entityRef}` : ''}${message.menuItemClicked.path}`;
        if (destination !== pathname) {
          navigate(destination);
        }
      }
    },
    [navigate, pathname]
  );

  usePostMessageHandler(handleMessageEvent);
};
