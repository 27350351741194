import { useFormik } from 'formik';
import { isNil, merge, set } from 'lodash';
import React, { useContext } from 'react';
import { useComponents } from './useComponents';

const FormContext = React.createContext();

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(`useFormContext cannot be rendered outside of the FormContext context provider`);
  }
  return context;
};

const defaultValues = {
  string: '',
  boolean: '',
  number: 0,
  array: [],
};

export const initializeValue = ({ config, initialValues }) => {
  // Fields might be grouped. Play nice either way.
  const groups = config?.groups || [{ fields: config?.fields }];

  const fields = groups.reduce((acc, group) => {
    acc = [...acc, ...group.fields];
    return acc;
  }, []);

  const registeredFieldsDefaults = fields?.reduce?.((acc, fieldConfig) => {
    const initialValue = initialValues?.[fieldConfig.path];
    const defaultValue = fieldConfig.defaultValue;

    let value;
    if (isNil(initialValue)) {
      value = defaultValue || defaultValues?.[fieldConfig.dataType || 'string'];
    } else {
      value = initialValue;
    }

    set(acc, fieldConfig.path, value);
    return acc;
  }, {});

  return merge(initialValues, registeredFieldsDefaults);
};

// const lineItems = React.useMemo(() => {
//   return order?.lineItems?.map(lineItem => (lineItem._id ? lineItem : { ...lineItem, _id: uuid() }));
// }, [order?.lineItems]);

export const FormProvider = ({ onSubmit, children, initialValues, config, disabled, components, ...props }) => {
  const [isErrorsVisible, setIsErrorsVisible] = React.useState(false);

  const parentFormContext = useContext(FormContext);

  const [_onSubmit, _setOnSubmit] = React.useState(null);

  const setOnSubmit = React.useCallback(fn => {
    _setOnSubmit(() => fn);
  }, []);

  const initialFormValue = React.useRef(initializeValue({ config, initialValues }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValue.current,
    onSubmit,
    validationSchema: config?.validationSchema,
  });

  const { validateForm } = formik;

  // React.useEffect(() => {
  //   const fieldToFocus = find(config?.fields, { initialFocus: true });
  //   if (fieldToFocus) {
  //     try {
  //       formMethods.setFocus(fieldToFocus.path);
  //     } catch {
  //       // The field hasn't been registered...ignore.
  //     }
  //   }
  // }, [config, formMethods]);

  const { Components } = useComponents({ config, components, setFieldValue: formik.setFieldValue });

  const overrideFormikResetForm = React.useCallback(
    options => {
      if (options?.values) {
        formik?.resetForm?.(options);
      } else if (options?.reinitialize) {
        formik?.resetForm?.({ values: initialFormValue.current });
      } else {
        formik?.resetForm?.();
      }
      // We have to wait a tick, otherwise the form will remain valid.
      setTimeout(() => {
        formik?.validateForm();
      });
      setIsErrorsVisible(false);
    },
    [formik]
  );

  const showErrors = React.useCallback(() => {
    setIsErrorsVisible(true);
  }, []);

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <FormContext.Provider
      value={{
        validationSchema: config?.validationSchema,
        config,
        ...formik,
        resetForm: overrideFormikResetForm,
        Components,
        parentFormContext,
        disabled,
        initialFormValue,
        onSubmit: _onSubmit || onSubmit,
        setOnSubmit,
        showErrors,
        isErrorsVisible,
        ...props,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
