import { isObject } from 'lodash';
import React from 'react';
import { Checkbox, FIELD_TYPES, Input, Select } from '../../form';
import { Array } from '../array/Array';
import { Quantity } from '../quantity/Quantity';
import { TimePicker } from '../time-picker/TimePicker';
import { TimeZonePicker } from '../time-zone-picker/TimeZonePicker';

const components = {
  input: React.forwardRef((props, ref) => <Input ref={ref} {...props} />),
  textArea: React.forwardRef((props, ref) => <Input ref={ref} {...props} multiline />),
  select: React.forwardRef((props, ref) => <Select ref={ref} {...props} />),
  checkbox: React.forwardRef((props, ref) => <Checkbox ref={ref} {...props} />),
  quantity: React.forwardRef((props, ref) => <Quantity ref={ref} {...props} />),
  time: React.forwardRef((props, ref) => <TimePicker ref={ref} {...props} />),
  array: React.forwardRef((props, ref) => <Array ref={ref} {...props} />),
  timeZone: React.forwardRef((props, ref) => <TimeZonePicker ref={ref} {...props} />),
};

export default fieldConfig => {
  const { Component } = fieldConfig;
  if (Component) {
    return React.forwardRef((props, ref) => <Component ref={ref} {...props} />);
  }

  switch (fieldConfig.dataType) {
    case FIELD_TYPES.string:
      if (fieldConfig.listValues) {
        return components.select;
      }
      if (fieldConfig.multiline) {
        return components.textArea;
      }
      return components.input;
    case FIELD_TYPES.array:
      if (isObject(fieldConfig?.arrayOf)) {
        return components.array;
      }
      return components.select; // currently the only component that can return an array.
    case FIELD_TYPES.boolean:
      return components.checkbox;
    default:
      return components[fieldConfig.dataType] || components.input;
  }
};
