import { get, isUndefined } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Input as CoreInput } from '../../core';
import { Column } from '../../layout';
import { Translate } from '../../localization';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from "../useIsFieldAllowed";
import { ValidationText } from '../validation-text/ValidationText';

import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Quantity = React.forwardRef((props, ref) => {
  const { values, setFieldValue, disabled, isSubmitting, errors, status, handleBlur } = useFormContext();
  const { fieldConfig, isRequired, uomPicker, uomCode } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };
  const isFieldAllowed = useIsFieldAllowed();

  const path = React.useMemo(() => {
    return fieldConfig?.path
      .split('.')
      .concat([fieldConfig?.valuePath])
      .filter(x => !isUndefined(x));
  }, [fieldConfig?.path, fieldConfig?.valuePath]);

  const value = React.useMemo(() => {
    return get(values, path);
  }, [path, values]);

  const handleQuantityChange = React.useCallback(
    val => {
      const preparedValue = { value: val, uomCode };

      const customValue = fieldConfig?.onChange?.(preparedValue);

      setFieldValue(fieldConfig.path, customValue || preparedValue);
    },
    [fieldConfig, setFieldValue, uomCode]
  );

  React.useEffect(() => {
    if (uomCode && uomCode !== value?.uomCode) {
      const preparedValue = { ...value, uomCode };
      const customValue = fieldConfig?.onChange?.(preparedValue);
      setFieldValue(fieldConfig.path, customValue || preparedValue);
    }
  }, [uomCode, value, setFieldValue, fieldConfig]);

  const uomStringId = React.useMemo(() => {
    const uom = value?.uomCode || uomCode;
    return uom ? `${value?.uomCode || uomCode}_text` : null;
  }, [uomCode, value?.uomCode]);

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.labelStringId}
        testId={`${fieldConfig.testId}`}
        isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
        disabled={disabled || isSubmitting}
      />
      <div className="controls">
        <Column>
          <CoreInput
            name={`${fieldConfig.path}.value`}
            onBlur={handleBlur}
            value={value?.value}
            onChange={handleQuantityChange}
            disabled={disabled || isSubmitting}
            type="text"
            testId={`${fieldConfig.testId}-value`}
            min={0}
            addonAfter={uomStringId && <Translate stringId={uomStringId} />}
            numeric
          />
          <ValidationText
            path={`${fieldConfig?.path}.value`}
            errors={errors}
            status={status}
            statusPath={fieldConfig?.path}
            name={fieldConfig.name}
          />
        </Column>
      </div>
    </Styled>
  );
});
