import React from 'react';
import { profiles } from '../../api';
import { DETAIL_TYPES, ListAndDetail } from '../../for_sdk/list-and-detail/ListAndDetail';
import config from './config';
import { ProfileEditor } from './ProfileEditor';

const onDelete = ({ entityRef, profileRef }) => profiles.deleteProfile({ entityRef, profileRef });

const onCreate = profile => {
  return profiles.createProfile({ profile });
};

const onUpdate = async profile => await profiles.updateProfile({ profileRef: profile.crn, profile });

export const Profiles = () => {

  const initialFetch = React.useMemo(() => {
    return { fn: profiles.listProfiles, cb: 'callback to filter out those already connected' };
  }, []);

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      config={config}
      Editor={ProfileEditor}
      initialFetch={initialFetch}
      updateFn={onUpdate}
      createFn={onCreate}
      deleteFn={onDelete}
    />
  );
};
