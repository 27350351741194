import { pick } from 'lodash';
import React from 'react';
import { entities } from '../../api';
import { DETAIL_TYPES, ListAndDetail } from '../../for_sdk/list-and-detail/ListAndDetail';
import { CompanyEditor } from './CompanyEditor';
import config from './entityConfig';

const createFn = async entity => {
  const pickedEntity = {
    ...pick(entity, ['name', 'crn', 'status', 'timeZone']),
    isTestEntity: entity?.isTestEntity || false,
  };
  return entities.createEntity({ entity: pickedEntity });
};

const updateFn = async entity => {
  const pickedEntity = {
    ...pick(entity, ['name', 'crn', 'status', 'timeZone']),
    isTestEntity: entity?.isTestEntity || false,
  };
  return await entities.updateEntity({ entityRef: entity.crn, entity: pickedEntity });
};

const deleteFn = entityRef => {
  return entities.deleteEntity({ entityRef });
};

export const Companies = () => (
  <ListAndDetail
    detailType={DETAIL_TYPES.VIEW}
    Editor={CompanyEditor}
    config={config}
    initialFetch={{ fn: entities.listEntities }}
    createFn={createFn}
    updateFn={updateFn}
    deleteFn={deleteFn}
  />
);
