import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import getPath from '../../../util/getPath';
import { TimePicker as CoreTimePicker } from '../../core';
import { useTimeZone } from '../../localization';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from "../useIsFieldAllowed";
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const TimeZone = ({ timeZone }) => {
  return <span style={{ paddingLeft: '5px' }}>({timeZone})</span>;
};

export const TimePicker = React.forwardRef((props, ref) => {
  const formContext = useFormContext();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values, validationSchema, formConfig } = formContext;
  const { fieldConfig, isRequired, local, onChange } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };
  const isFieldAllowed = useIsFieldAllowed();

  const handleChange = React.useCallback(
    value => {
      onChange(value);
    },

    [onChange]
  );

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    return get(values, path, '');
  }, [fieldConfig, values]);

  const now = React.useMemo(() => {
    return new Date();
  }, []);

  const timeZone = useTimeZone({ date: now, local });

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.labelStringId}
        data-testid={fieldConfig.testId}
        isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
        disabled={disabled || isSubmitting}
        additionalText={<TimeZone timeZone={timeZone} />}
      />
      <CoreTimePicker
        name={fieldConfig.path}
        ref={ref}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled || isSubmitting}
        testId={`${fieldConfig.testId}-form-field`}
        local={local}
      />
      <ValidationText path={fieldConfig.path} name={fieldConfig.name} />
    </Styled>
  );
});
