import cn from 'classnames';
import { Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DETAIL_TYPES } from '../../for_sdk/list-and-detail/ListAndDetail';
import { useListAndDetailContext } from '../../for_sdk/list-and-detail/ListAndDetailProvider';
import style from './style';
import tabsConfig from './tabs/config';

const Styled = styled.div`
  ${style}
`;

export const CompanyEditor = ({updateFn, createFn, deleteFn}) => {
  const { entityRef } = useParams();
  const { values, mode } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { detailType } = useListAndDetailContext();
  const navigate = useNavigate();

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`/companies`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [closeDrawer, detailType, entityRef, navigate]);

  const handleCreate = React.useCallback(
    entity =>
      createFn?.(entity)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createFn, onFinally]
  );

  const handleUpdate = React.useCallback(
    entity =>
      updateFn?.(entity)?.then(response => {
        onFinally?.();
        return response;
      }),
    [onFinally, updateFn]
  );

  const handleDelete = React.useCallback(
    entityRef =>
      deleteFn?.(entityRef)?.then(response => {
        onFinally?.();
        return response;
      }),
    [deleteFn, onFinally]
  );

  return (
    <Styled className={cn('company-editor')}>
      <Typography.H2>
        {mode === 'edit' ? (
          values?.name
        ) : (
          <Localization.Translate stringId="createEntity" data-testid="create-company-header" />
        )}
      </Typography.H2>
      <hr />
      {/*Props, other than config, are passed to each underlying tab component.*/}
      <Form.Tabs
        config={tabsConfig}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onFinally={onFinally}
      />
    </Styled>
  );
};
