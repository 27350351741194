import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../localization';
import { Subtitle } from '../../typography';
import { RequiredIndicator } from './RequiredIndicator';

const Styled = styled.label`
  height: 22px;
  color: var(--form-element-text-color);
  display: inline-flex;

  &.busy {
    .subtitle {
      position: relative;

      .spinner {
        position: absolute;
        top: -4px;
        right: -25px;
        width: 14px;
        font-size: 14px;
        line-height: 34px;
        color: var(--color-text-page);
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
        animation-name: fa-spin;
        animation-duration: var(--fa-animation-duration, 2s);
        animation-iteration-count: var(--fa-animation-iteration-count, infinite);
        animation-timing-function: var(--fa-animation-timing, linear);
        animation-delay: var(--fa-animation-delay, 0);
        animation-direction: var(--fa-animation-direction, normal);
        //-webkit-font-smoothing: antialiased;
        //display: var(--fa-display, inline-block);
        //font-style: normal;
        //font-variant: normal;
        //line-height: 1;
        //text-rendering: auto;
        :before {
          content: '\f110';
        }

        input {
          display: none;
        }
      }
    }
  }
`;

export const Label = ({
  stringId,
  values,
  testId: testIdDeprecated,
  'data-testid': dataTestId,
  isRequired,
  disabled,
  children,
  additionalText = null,
  busy,
}) => {
  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  return (
    <Styled className={cn('label', { busy })}>
      {children}
      <Subtitle>
        <Translate stringId={stringId} data-testid={`${testId}-label`} values={values} />
        {additionalText}
        <RequiredIndicator required={isRequired} disabled={disabled} testId={testId} />
        <div className="spinner" />
      </Subtitle>
    </Styled>
  );
};
