import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import pkg from '../package.json';
import { AuthProvider } from './components/auth';
import { DrawerProvider } from './components/drawer';
import { Localization } from './components/localization';
import { Column } from './components/layout';
import { ModalProvider } from './components/modal';
import { ThemeProvider } from './components/theme';
import { AppListener } from './AppListener';
import { UserProvider } from './components/user';
import { PostMessage } from './post-message/PostMessage';
import { ProductionWarningProvider } from './ProductionWarningProvider';
import { useAppProductionWarning } from './useAppProductionWarning';
import { getCachedItem } from './util/cache';
import { EntityContextProvider } from './util/entity-provider/EntityProvider';

const Styled = styled(Column)`
  transition: all 0.3s;
  background-color: var(--color-background-page);
  color: var(--color-text-page);
  font-family: 'Work Sans', serif;
  height: 100vh;
  width: 100%;
  gap: 0;
  .ant-drawer-body {
    padding-top: 0;
  }
`;

export const ConnexDesignSystem = ({
  onLogin,
  toLogin,
  children,
  className,
  messages,
  initialLocale,
  initialTimeZone,
  app,
  isIframe = true,
  appId,
  ...props
}) => {
  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`, className)} {...props}>
      <PostMessage isIframe={isIframe} sendMessages={true}>
        <Localization
          messages={messages}
          initialLocale={initialLocale || getCachedItem('language')}
          initialTimeZone={initialTimeZone || getCachedItem('timeZone')}
          isIframe={isIframe}
          appId={appId}
        >
          <ThemeProvider isIframe={isIframe}>
            <UserProvider>
              <AuthProvider onLogin={onLogin} toLogin={toLogin}>
                <ModalProvider>
                  <DrawerProvider>
                    <AppListener>
                      <EntityContextProvider>
                        <ProductionWarningProvider>{children}</ProductionWarningProvider>
                      </EntityContextProvider>
                    </AppListener>
                  </DrawerProvider>
                </ModalProvider>
              </AuthProvider>
            </UserProvider>
          </ThemeProvider>
        </Localization>
      </PostMessage>
    </Styled>
  );
};
