import React from 'react';
import { apps } from '../../api';
import { DETAIL_TYPES, ListAndDetail } from '../../for_sdk/list-and-detail/ListAndDetail';
import config from './config';

const createFn = async app => {
  return apps.createApp({ app });
};

const updateFn = async app => {
  return await apps.updateApp({ appRef: app.crn, app });
};

const deleteFn = appRef => {
  return apps.deleteApp({ appRef });
};

export const Apps = () => (
  <ListAndDetail
    detailType={DETAIL_TYPES.DRAWER}
    config={config}
    initialFetch={{ fn: apps.listApps }}
    createFn={createFn}
    updateFn={updateFn}
    deleteFn={deleteFn}
  />
);
