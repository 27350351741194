import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import { Helper } from '../../typography';
import { Translate } from '../../localization';
import { useFormContext } from '../context-provider/FormProvider';

const StyledError = styled(Helper)`
  height: 16px;
  color: var(--color-text-validation);
`;

const StyledPlaceholder = styled.div`
  height: 16px;
`;

export const ValidationText = ({ path, name, status, statusPath }) => {
  const formContext = useFormContext();
  const { errors, touched, isErrorsVisible, mode } = formContext;

  const error = get(errors, path) || get(status, statusPath);

  return error && (mode === 'edit' || get(touched, path) || isErrorsVisible) ? (
    <StyledError>
      <Translate stringId={error} data-testid={`validation-error-${name || path}-${error}`} />
    </StyledError>
  ) : (
    <StyledPlaceholder />
  );
};
